/**
 * Direttiva che costruisce la barra di ricerca con i filtri. E' come la direttiva <search-bar> ma, di differente, ha il fatto di avere le variabili dello $scope in two-way-binding. Questo serve quando, ad esempio, ho una pagina di ricerca che non ha bisogno di salvare le ricerche nella cronologia (e, pertanto, non aggiornando la pagina ad ogni ricerca, non aggiornerebbe neppure le variabili)
 */
import { Item, User, SenecaResponse, UserGroup } from "atfcore-commonclasses";

export interface ISearchBarDynamicDirectiveScope extends ng.IScope {
	libraryApplicationData: any;
	isBackBtnDisabled: boolean;
	filters: any;
	searchId: number;
	isSearchBtnDisabled: boolean;
	isDoingSomething: boolean;
	hasTabsAtLeastOneFilter: Function;
	countTabFilters: Function;
	filtersOpened: any;
	subTitleCategory: string;
	subTitleCluster: string;
	clustersSelected: boolean;
	clusterElementCSS: string;
	subTitleFunctionalArea: string;
	funcionalAreaSelected: boolean;
	subTitleLevel: string;
	levelSelected: boolean;
	levelListSubTitle: string;
	levelList: any;
}
angular.module('app').directive("searchBarDynamic", ($window, $state, LibraryApplicationData, GlobalApplicationData, $document, moment, $stateParams, $sessionStorage, $mdDialog, blockUI, LearningPlanService, toaster, $translate, $rootScope, ProjectService, VisibilityService, $timeout, ItemDetailService, GroupService) => {
	return {
		restrict: 'E',
		transclude: true,
		scope: {
			filters: '=',
			saveNewSearch: '=',
			historyBack: '=',
			searchId: '=',
			isDoingSomething: '=',
			hasTabsAtLeastOneFilter: '=',
			removeFilters: '=',
			countTabFilters: '=',
			subTabLibraryFiltersChanged: '=',
			inputPlaceholder: '=',
			filtersOpened: '=',
			expandFilters: '=',
			categoryList: '=',
			getClusterClass: '=',
			unsetLibraryFilter: '=',
			setLibraryFilter: '=',
			functionalAreaList: '=',
			levelList: '=',
			typeList: '=',
			filtersContainerClass: '=',
			competenceList: '=',
			statusList: '=',
			mergedCompetenceList: '=',
			streamTagList: '=',
			categoryIndex: '=',
			functionalAreaIndex: '=',
			competenceIndex: '=',
			changeLetters: '=',
			isFirstTabSelected: '=',
			atLeastOneTypeSelected: '=',
			noStatusSelected: '=',
			noApproachSelected: '=',
			techApproachSelected: '=',
			softApproachSelected: '=',
			softApproachClass: '=',
			techApproachClass: '=',
			selectedCompetencesLength: '=',
			adminTagList: '=',
			clusterList: '=',
			isFilterAlreadySelected: '=',
			searchItems: '=',
			isAdmin: '=',
			softSkillsTags: '='
		},
		link: link,
		templateUrl: 'app/shared/searchBarDynamic/searchBarDynamic.html'
	};
	function link($scope: ISearchBarDynamicDirectiveScope, element: JQuery, attrs: ng.IAttributes) {
		// Collegamento all'oggetto con le costanti
		$scope.libraryApplicationData = LibraryApplicationData;

		// Se non ho ancora effettuato una ricerca, disabilito il pulsante per tornare indietro a fianco l'input di ricerca
		if ($scope.searchId <= 1) {
			$scope.isBackBtnDisabled = true;
		}

		// Calcolo la class per l'argomento selezionato
		if ($scope.filters.selectedTopicTags && $scope.filters.selectedTopicTags.length) {
			$scope.subTitleCategory = "1 " + $translate.instant('librarySubFilters.CATEGORY_SELECTED');
		} else {
			$scope.subTitleCategory = "0 " + $translate.instant('librarySubFilters.CATEGORIES_SELECTED');
		}

		// Testo in traduzione nei cluster
		if ($scope.filters.selectedClusterTags && $scope.filters.selectedClusterTags.length) {
			// Segnalo che è stato selezionato almeno un cluster
			$scope.clustersSelected = true;
			if ($scope.filters.selectedClusterTags.length == 1) {
				$scope.subTitleCluster = $translate.instant('librarySubFilters.CLUSTER_SELECTED');
			} else {
				$scope.subTitleCluster = $translate.instant('librarySubFilters.CLUSTERS_SELECTED');
			}
		} else {
			$scope.subTitleCluster = $translate.instant('librarySubFilters.CLUSTERS_SELECTED');
		}

		// Aggiungo l'etichetta per il filtro delle aree funzionali
		if ($scope.filters.selectedFunctionalAreaTags && $scope.filters.selectedFunctionalAreaTags.length) {
			// Segnalo che c'è almeno un'area funzionale selezionata
			$scope.funcionalAreaSelected = true;
			if ($scope.filters.selectedFunctionalAreaTags.length == 1) {
				$scope.subTitleFunctionalArea = $translate.instant('librarySubFilters.FUNCTIONAL_AREA_SELECTED');
			} else {
				$scope.subTitleFunctionalArea = $translate.instant('librarySubFilters.FUNCTIONAL_AREAS_SELECTED');
			}
		} else {
			$scope.subTitleFunctionalArea = $translate.instant('librarySubFilters.FUNCTIONAL_AREAS_SELECTED');
		}

		// Aggiungo l'etichetta per il filtro dei livelli
		if ($scope.filters.selectedLevelTags && $scope.filters.selectedLevelTags.length) {
			// Segnalo che c'è almeno un livello selezionato
			$scope.levelSelected = true;
			if ($scope.filters.selectedLevelTags.length == 1) {
				$scope.subTitleLevel = $translate.instant('librarySubFilters.LEVEL_SELECTED');
			} else {
				$scope.subTitleLevel = $translate.instant('librarySubFilters.LEVELS_SELECTED');
			}
		} else {
			$scope.subTitleLevel = $translate.instant('librarySubFilters.LEVELS_SELECTED');
		}

		// Aggiungo l'etichetta appropriata per la lista dei livelli da selezionare
		if ($scope.levelList && $scope.levelList.length) {
			if ($scope.levelList.length == 1) {
				$scope.levelListSubTitle = $translate.instant('librarySubFilters.LEVEL_TOTAL');
			} else {
				$scope.levelListSubTitle = $translate.instant('librarySubFilters.LEVELS_TOTAL');
			}
		} else {
			$scope.levelListSubTitle = $translate.instant('librarySubFilters.LEVELS_TOTAL');
		}
	}
});